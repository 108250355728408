import { EditorSDK, EventType } from '@wix/platform-editor-sdk';
import Experiments, { ExperimentsBag } from '@wix/wix-experiments';
import 'reflect-metadata';
import { IEditorQuery, IOpenSidePanel } from './editor.types';
import { editorContainer } from './editor/ioc/container';
import { inversifyEditorConfig } from './editor/ioc/inversify.config';
import { editorServices } from './editor/ioc/services';
import { IEditorStoreService } from './editor/services/EditorStoreService/EditorStoreService.types';
import { siteChangingEvents } from './services/EditorEventsService/EditorEventsService.types';
import { getComponentAppSettings } from './utils/ambassador/AppSettingsService/getComponentAppSettings/getComponentAppSettings';
import { setComponentAppSettings } from './utils/ambassador/AppSettingsService/setComponentAppSettings/setComponentAppSettings';
import { getBusinessManagerSiteLanguage } from './utils/getBusinessManagerSiteLanguage/getBusinessManagerSiteLanguage';
import { getDataScienceSiteLanguage } from './utils/getDataScienceSiteLanguage/getDataScienceSiteLanguage';
import { getWixStoresProductsList } from './utils/getWixStoresProductsList/getWixStoresProductsList';
import { hasImageTextByDataScience } from './utils/hasImageTextByDataScience/hasImageTextByDataScience';
import { setBusinessManagerSiteLanguage } from './utils/setBusinessManagerSiteLanguage/setBusinessManagerSiteLanguage';
import { setWixStoresProductsMediaItems } from './utils/setWixStoresProductsMediaItems/setWixStoresProductsMediaItems';

inversifyEditorConfig(editorContainer);

const sidePanelHtmlPath = '/assets/side-panel.html';
const isProduction = process.env.NODE_ENV === 'production';

function getSidePanelUrl() {
  if (isProduction) {
    return `.${sidePanelHtmlPath}`;
  }

  return `https://localhost:3200${sidePanelHtmlPath}`;
}

async function getExperiments(): Promise<ExperimentsBag> {
  const experiments = new Experiments({
    useNewApi: true,
  });
  await experiments.load('accessibility-wizard');
  return experiments.all();
}

let sendEvent = (_event: EventType) => {};
let openSidePanel = (_params: IOpenSidePanel) => {};

export const editorReady = async (editorSdk: EditorSDK, token: string) => {
  const editorSdkVersion = editorSdk.info.getSdkVersion(token);

  if (editorSdk.document.application.registerToCustomEvents) {
    await editorSdk.document.application.registerToCustomEvents(token, {
      eventTypes: siteChangingEvents,
    });
  }

  await setPublicAppAPI(token, editorSdk);

  const sidePanelUrl = getSidePanelUrl();

  openSidePanel = ({ editorQuery }) =>
    openSidePanelFunction({
      token,
      editorSdk,
      editorSdkVersion: editorSdkVersion.version,
      sidePanelUrl,
      editorQuery,
    });
};

export const onEvent = async ({ eventType }: { eventType: EventType }) => {
  sendEvent(eventType);
};

const setPublicAppAPI = async (token: string, editorSDK: EditorSDK) => {
  await editorSDK.editor.setAppAPI(token, publicApi);
};

const openSidePanelFunction = async ({
  token,
  editorSdk,
  editorSdkVersion,
  sidePanelUrl,
  editorQuery,
}: {
  token: string;
  editorSdk: EditorSDK;
  editorSdkVersion: string;
  sidePanelUrl: string;
  editorQuery: IEditorQuery;
}) => {
  const experiments = await getExperiments();

  await editorSdk.editor.openSidePanel(token, {
    url: `${sidePanelUrl}?editorSdkVersion=${editorSdkVersion}`,
    initialData: {
      editorQuery,
      experiments,
    },
    displayAboveModals: false,
  });
};

const editorStoreService = editorContainer.get<IEditorStoreService>(
  editorServices.store,
);

const publicApi = {
  openSidePanel: ({ editorQuery }: IOpenSidePanel) => {
    openSidePanel({ editorQuery });
  },
  _subscribeEditorEvent: (callback: (event: EventType) => void) => {
    sendEvent = callback;
  },
  _getBusinessManagerSiteLanguage: getBusinessManagerSiteLanguage,
  _setBusinessManagerSiteLanguage: setBusinessManagerSiteLanguage,
  _getDataScienceSiteLanguage: getDataScienceSiteLanguage,
  _hasImageTextByDataScience: hasImageTextByDataScience,
  _getStoreJson: () => editorStoreService.getStoreJson(),
  _setStoreJson: (json: string) => editorStoreService.setStoreJson(json),
  _getComponentAppSettings: getComponentAppSettings,
  _setComponentAppSettings: setComponentAppSettings,
  _getWixStoresProductsList: getWixStoresProductsList,
  _setWixStoresProductsMediaItems: setWixStoresProductsMediaItems,
};

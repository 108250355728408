import {
  PropertiesUpdateResponse,
  SitePropertiesService,
} from '@wix/ambassador-site-properties-service/http';
import { AmbassadorBaseUrl } from '../../constants/ambassador';

export async function setBusinessManagerSiteLanguage(
  authorization: string,
  language: string,
): Promise<PropertiesUpdateResponse> {
  const sitePropertiesService = SitePropertiesService(
    AmbassadorBaseUrl.SitePropertiesService,
  ).SitePropertiesV4()({
    authorization,
  });

  return sitePropertiesService.update({
    fields: {
      paths: ['language'],
    },
    properties: {
      language,
    },
  });
}

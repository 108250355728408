import { injectable } from 'inversify';
import { IEditorStoreService } from './EditorStoreService.types';

@injectable()
export class EditorStoreService implements IEditorStoreService {
  private storeJson: string | undefined = undefined;

  public getStoreJson() {
    return this.storeJson;
  }

  public setStoreJson(store: string) {
    this.storeJson = store;
  }
}
